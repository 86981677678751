<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="!form" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <UiContainer v-else large class="tw-pb-24" :key="sectionKey">
      <form class="tw-flex tw-flex-col tw-gap-6" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <div class="tw-p-6">
          <span class="tw-font-semibold tw-text-lg">{{ $t('forms.individual.settings.informations.title') }}</span>

          <div class="md:tw-grid tw-grid-cols-3 tw-mt-4">
            <div class="text-center">
              <div class="tw-font-semibold tw-text-sm">
                {{ $t('forms.individual.settings.informations.id.client') }}
              </div>
              <v-btn class="tw-mt-2" text outlined rounded @click="copyId('client')"
                ><v-icon left dark> {{ icons.mdiContentCopy }} </v-icon
                >{{ $t('forms.individual.settings.informations.id.button') }}</v-btn
              >
            </div>

            <div class="text-center">
              <div class="tw-font-semibold tw-text-sm">
                {{ $t('forms.individual.settings.informations.id.form') }}
              </div>
              <v-btn class="tw-mt-2" text outlined rounded @click="copyId('form')"
                ><v-icon left dark> {{ icons.mdiContentCopy }} </v-icon
                >{{ $t('forms.individual.settings.informations.id.button') }}</v-btn
              >
            </div>

            <div class="text-center">
              <div class="tw-font-semibold tw-text-sm">
                {{ $t('forms.individual.settings.informations.createdAt') }}
              </div>
              <div class="tw-text-sm tw-mt-4">{{ computedDate }}</div>
            </div>
          </div>
        </div>

        <!-- Global configuration -->
        <UiCardForm>
          <div>
            <div class="tw-font-semibold tw-text-lg">
              {{ $t('forms.individual.settings.global.title') }}
            </div>

            <UiInputToggle
              class="tw-mt-4"
              v-model="modifiedSettings.located"
              :title="$t(`forms.individual.settings.global.located.title`)"
              :subtitle="$t(`forms.individual.settings.global.located.subtitle`)"
            />

            <UiInputToggle
              class="tw-mt-4"
              v-model="modifiedSettings.product"
              :title="$t(`forms.individual.settings.global.product.title`)"
              :subtitle="$t(`forms.individual.settings.global.product.subtitle`)"
            />

            <div class="tw-font-medium tw-text-base tw-mt-6">
              {{ $t('forms.individual.settings.global.idleDaysBeforeLeadLoss.new.title') }}
            </div>
            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t('forms.individual.settings.global.idleDaysBeforeLeadLoss.new.description') }}
            </div>

            <v-text-field
              v-model.number="modifiedSettings.idleDaysBeforeLeadLoss.new"
              id="idleDaysBeforeLeadLossNew"
              type="number"
              min="0"
              class="tw-mt-4"
              solo
              flat
              dense
              outlined
            />

            <div class="tw-font-medium tw-text-base">
              {{ $t('forms.individual.settings.global.idleDaysBeforeLeadLoss.accepted.title') }}
            </div>
            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t('forms.individual.settings.global.idleDaysBeforeLeadLoss.accepted.description') }}
            </div>

            <v-text-field
              v-model.number="modifiedSettings.idleDaysBeforeLeadLoss.accepted"
              id="idleDaysBeforeLeadLossAccepted"
              type="number"
              min="0"
              class="tw-mt-4"
              solo
              flat
              dense
              outlined
            />
          </div>

          <div class="tw-mt-6">
            <div class="tw-font-medium tw-text-base">
              {{ $t('forms.individual.settings.domains.title') }}
            </div>
            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t('forms.individual.settings.domains.description') }}
            </div>
            <v-combobox
              class="tw-mt-4 domain-combo"
              v-model="modifiedSettings.authorizedDomains"
              chips
              clearable
              :label="$t('forms.individual.settings.domains.placeholder')"
              multiple
              outlined
              hide-details
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="removeDomain(item)">
                  <span>{{ item }}</span>
                </v-chip>
              </template>
            </v-combobox>
          </div>

          <div class="tw-mt-6">
            <div class="tw-font-medium tw-text-base">
              {{ $t('forms.individual.settings.captcha.title') }}
            </div>

            <v-radio-group v-model="modifiedSettings.captcha">
              <v-radio value="google" class="tw-mb-0 tw-bg-white">
                <template v-slot:label>
                  <img
                    :src="require(`@/assets/images/logo-google-recaptcha.svg`)"
                    alt="Logo google recaptcha"
                    height="40px"
                    width="40px"
                    class="tw-ml-5"
                  />
                  <div class="tw-flex-auto tw-ml-5">
                    <div class="tw-flex tw-justify-between tw-mb-1">
                      <div class="tw-font-medium tw-text-base">
                        {{ $t('forms.individual.settings.captcha.google.title') }}
                      </div>
                      <div>
                        <a
                          @click.stop.prevent="goTo('https://www.google.com/recaptcha/about/', '_blank')"
                          href="https://www.google.com/recaptcha/about/"
                          class="tw-text-sm"
                          >{{ $t('forms.individual.settings.captcha.linkLabel') }}</a
                        >
                      </div>
                    </div>
                    <div class="tw-text-xs tw-text-gray-500">
                      {{ $t('forms.individual.settings.captcha.google.description') }}
                    </div>
                  </div>
                </template>
              </v-radio>
              <v-radio value="friendly" class="tw-pt-6 tw-bg-white tw-opacity-50" disabled>
                <template v-slot:label>
                  <img
                    :src="require(`@/assets/images/logo-friendly-captcha.svg`)"
                    alt="Logo friendly captcha"
                    width="40px"
                    class="tw-ml-5"
                  />
                  <div class="tw-flex-auto tw-ml-5">
                    <div class="tw-flex tw-justify-between tw-mb-1">
                      <div class="tw-font-medium tw-text-base">
                        {{ $t('forms.individual.settings.captcha.friendly.title') }}
                      </div>
                      <a
                        @click.stop.prevent="goTo('https://friendlycaptcha.com/', '_blank')"
                        href="https://friendlycaptcha.com/"
                        class="tw-text-sm"
                        >{{ $t('forms.individual.settings.captcha.linkLabel') }}</a
                      >
                    </div>
                    <div class="tw-text-xs tw-text-gray-500">
                      {{ $t('forms.individual.settings.captcha.friendly.description') }}
                    </div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </UiCardForm>

        <!-- Monetization configuration -->
        <UiCardForm>
          <UiInputToggle
            v-model="modifiedSettings.monetized"
            :largeTitle="$t(`forms.individual.settings.global.monetized.title`)"
          />

          <div v-if="modifiedSettings.monetized">
            <div class="tw-font-medium tw-text-base tw-mt-6">
              {{ $t('forms.individual.settings.global.currency.title') }}
            </div>
            <v-select
              class="tw-text-sm tw-mt-4"
              v-model.trim="modifiedSettings.currency"
              solo
              flat
              dense
              outlined
              :items="currencies"
              :placeholder="$t('forms.individual.settings.global.currency.title')"
            />

            <div class="tw-font-medium tw-text-base">
              {{ $t('forms.individual.settings.global.defaultLeadPrice.title') }}
            </div>
            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t('forms.individual.settings.global.defaultLeadPrice.description') }}
            </div>
            <price
              class="tw-mt-4"
              :price="modifiedSettings.defaultLeadPrice"
              :currency="modifiedSettings.currency"
              :locale="locale"
              @onPriceChange="onPriceChange"
            />
          </div>
        </UiCardForm>

        <!-- Lexicon -->
        <UiCardForm>
          <div>
            <div class="tw-font-semibold tw-text-lg">
              {{ $t('forms.individual.settings.lexicon.title') }}
            </div>
            <div class="tw-text-xs tw-text-gray-500 tw-my-1">
              {{ $t('forms.individual.settings.lexicon.description') }}
            </div>
            <div class="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-4">
              <div v-for="attribute in lexiconEntries" :key="attribute">
                <FormsIndividualLocalizedInput
                  :defaultContent="modifiedSettings.lexicon[attribute] || {}"
                  :title="$t(`forms.individual.settings.lexicon.${attribute}.label`)"
                  :placeholder="$t(`forms.individual.settings.lexicon.${attribute}.label`)"
                  @input="editLexiconAttribute($event, attribute)"
                />
              </div>
            </div>
          </div>
        </UiCardForm>
        <PlatformFormsIndividualUIEditActions v-if="hasEditedContent" type="submit" @cancelEdit="cancelEdit" />
      </form>
    </UiContainer>
  </v-fade-transition>
</template>

<script>
import { mapActions } from 'vuex'
import UiContainer from '@/components/UI/Container.vue'
import PlatformFormsIndividualUIEditActions from '@/components/Forms/Individual/UI/EditActions.vue'
import UiInputToggle from '@/components/UI/input/InputToggle.vue'
import UiCardForm from '@/components/UI/CardForm.vue'
import { formatedDate } from '@/utils/date.util'
import { clone } from '@/utils/utilities.util'
import {
  mdiContentCopy,
  mdiContentSaveCheckOutline,
  mdiCloseCircle,
  mdiPlaylistEdit,
  mdiPlus,
  mdiAlertBox,
  mdiPencilCircleOutline,
} from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { currencies } from '@/config/currencies.config'
import Price from '@/components/UI/input/Price.vue'
import FormsIndividualLocalizedInput from '@/components/Forms/Individual/LocalizedInput.vue'

export default {
  name: 'PlatformFormsIndividualSettings',
  components: {
    UiContainer,
    UiInputToggle,
    UiCardForm,
    Price,
    PlatformFormsIndividualUIEditActions,
    FormsIndividualLocalizedInput,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
    conditionableFields: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    formatedDate,
    sectionKey: 0,
    modifiedSettings: {
      authorizedDomains: [],
      currency: null,
      defaultLeadPrice: null,
      idleDaysBeforeLeadLoss: {
        new: 60,
        accepted: 60,
      },
      monetized: false,
      product: false,
      located: false,
      lexicon: {},
    },
    initialSettings: null,
    icons: {
      mdiContentCopy,
      mdiContentSaveCheckOutline,
      mdiCloseCircle,
      mdiPlaylistEdit,
      mdiPlus,
      mdiAlertBox,
      mdiPencilCircleOutline,
    },
    currencies,
  }),
  computed: {
    computedDate() {
      return formatedDate(this.form.createdAt, 'L LT')
    },
    lexiconEntries() {
      const terms = ['tags', 'additionalData', 'fieldValues', 'identity']
      if (this.modifiedSettings.located) {
        terms.push(...['location', 'locations', 'company', 'subscriber', 'observer'])
      }
      if (this.modifiedSettings.product) {
        terms.push('skus')
      }
      return terms
    },
    hasEditedContent() {
      return JSON.stringify(this.modifiedSettings) != JSON.stringify(this.initialSettings)
    },
  },

  watch: {
    modifiedSettings: {
      handler() {
        this.$emit('contentModified', this.hasEditedContent, 'settings')
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.initSettings()
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),

    initSettings() {
      if (this.form?.settings) {
        this.modifiedSettings = {
          ...this.modifiedSettings,
          ...this.form.settings,
          lexicon: this.form.settings.lexicon ? JSON.parse(JSON.stringify(this.form.settings.lexicon)) : {},
        }

        if (this.form.settings.authorizedDomains) {
          this.modifiedSettings.authorizedDomains = [...this.form.settings.authorizedDomains]
          this.selectedDomains = this.modifiedSettings.authorizedDomains
        }

        this.initialSettings = clone(this.modifiedSettings)
      }
    },

    cancelEdit() {
      this.initSettings()
      this.sectionKey++
    },

    goTo(url) {
      window.open(url, '_blank')
    },

    async copyId(type) {
      try {
        const idToCopy = type === 'client' ? this.form.clientId : this.form.id
        await navigator.clipboard.writeText(idToCopy)
        this.setAlert({
          color: 'success',
          text: this.$t('label.success'),
        })
      } catch ($e) {
        this.setAlert({
          color: 'error',
          text: this.$t('label.fail'),
        })
      }
    },

    removeDomain(item) {
      this.modifiedSettings.authorizedDomains.splice(this.modifiedSettings.authorizedDomains.indexOf(item), 1)
    },

    onSubmit() {
      this.$emit('updateSettings', this.modifiedSettings)
      this.resetState()
    },

    resetState() {
      this.initSettings()
    },
    onPriceChange(price) {
      this.modifiedSettings.defaultLeadPrice = price
      if (isNaN(price) || price === '') this.modifiedSettings.defaultLeadPrice = null
    },
    editLexiconAttribute(event, attribute) {
      for (const [key, value] of Object.entries(event)) {
        if (null === value) delete event[key]
      }
      if (!Object.keys(event).length) {
        delete this.modifiedSettings.lexicon[attribute]
        return
      }
      this.modifiedSettings.lexicon = {
        ...this.modifiedSettings.lexicon,
        [attribute]: event,
      }
    },
  },
}
</script>

<style lang="scss">
.domain-combo {
  .v-input__append-inner {
    display: none !important;
  }
}
</style>
