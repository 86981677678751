<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="!form" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <UiContainer v-else class="forms-individual-integration tw-overflow-y-visible tw-mt-8 tw-w-full tw-h-full">
      <span class="tw-font-semibold tw-text-lg"> {{ $t(`forms.individual.integration.informations.title`) }} </span>
      <div class="tw-my-4">{{ $t(`forms.individual.integration.informations.description`) }}</div>
      <div class="forms-individual-integration__form-settings">
        <div class="forms-individual-integration__form-settings__form">
          <div class="tw-px-4 tw-flex">
            <v-switch
              v-model="seeCode"
              class="tw-mr-4"
              hide-details
              :label="$t(`forms.individual.integration.forms.code`)"
            ></v-switch>
            <v-switch
              v-if="hasProducts"
              v-model="hasTracking"
              hide-details
              :label="$t(`forms.individual.integration.forms.script`)"
            ></v-switch>
          </div>

          <UiCardForm
            :class="[
              {
                'forms-individual-integration__form-settings__form__background dark': colorMode === 0,
                'forms-individual-integration__form-settings__form__background light': colorMode === 1,
              },
            ]"
          >
            <div v-show="!seeCode">
              <UiFormWidget
                v-if="hasMandatoryWidgetAttributes"
                :id="form.id"
                :locale="locale"
                :dark="colorMode === 0"
                :font="customFont ? customFont : font"
                :color="color"
                :cssUrl="cssUrl"
                :radius="computedBorderRadius"
                :fontSize="fontSize"
                :dense="isDense"
                :outlined="isOutlined"
                :flat="isFlat"
                :clearable="areClearables"
              ></UiFormWidget>
            </div>

            <div v-if="seeCode">
              <Highlight
                class="forms-individual-integration__form-settings__form__code"
                language="xml"
                :code="generatedCode"
              />
            </div>
          </UiCardForm>
          <div v-if="seeCode" class="tw-px-4 tw-pb-4">
            <v-btn color="primary" rounded @click="copyCode">
              <v-icon left dark> {{ icons.mdiContentCopy }} </v-icon>
              {{ $t(`forms.individual.integration.forms.copy.button`) }}
            </v-btn>
          </div>
        </div>

        <div
          class="forms-individual-integration__form-settings__settings"
          :class="{ 'tw-interface-dark-grade-1-color': $vuetify.theme.dark }"
        >
          <div class="tw-p-4 tw-pb-0 tw-mb-4 tw-font-medium">
            {{ $t(`forms.individual.integration.settings.title`) }}
          </div>
          <v-divider class="ui-separator-title"></v-divider>
          <div class="forms-individual-integration__form-settings__settings__content">
            <div class="tw-relative">
              <div class="tw-mt-4 tw-px-4">
                <div class="tw-mb-4" v-dompurify-html="$t('forms.individual.integration.settings.lang')"></div>
                <v-btn-toggle v-model="selectableLocale" color="primary" mandatory>
                  <v-btn v-for="lang in form.translations.activatedLocales" :key="lang" @click="assignLocale(lang)">
                    <span>{{ lang }}</span>
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-divider class="ui-separator"></v-divider>
              <div class="tw-mt-4 tw-px-4">
                <div class="tw-mb-4" v-dompurify-html="$t('forms.individual.integration.settings.cssUrl')"></div>
                <v-text-field v-model="cssUrl" id="cssUrl" solo flat outlined hide-details />
              </div>
              <v-divider class="ui-separator"></v-divider>
              <div class="tw-mt-4 tw-px-4 tw-flex">
                <div>
                  <div class="tw-mb-4" v-dompurify-html="$t('forms.individual.integration.settings.color')"></div>

                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :color="color" dark v-bind="attrs" v-on="on" height="48"> </v-btn>
                    </template>
                    <UiCardForm>
                      <v-card-text class="tw-p-0">
                        <v-color-picker v-model="color" mode="hexa" :value="selectedColor" />
                      </v-card-text>
                    </UiCardForm>
                  </v-menu>
                </div>
                <v-spacer></v-spacer>
                <div>
                  <div class="tw-mb-4" v-dompurify-html="$t('forms.individual.integration.settings.mode')"></div>
                  <v-btn-toggle v-model="colorMode" color="primary" mandatory>
                    <v-btn>
                      <span>Dark</span>
                    </v-btn>

                    <v-btn>
                      <span>Light</span>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
              <v-divider class="ui-separator"></v-divider>
              <div class="tw-mt-4 tw-px-4">
                <v-btn-toggle
                  v-model="fontMode"
                  class="tw-mb-4 tw-w-full"
                  color="primary"
                  mandatory
                  @change="fontModeChange"
                >
                  <v-btn class="tw-w-1/2">
                    <span>{{ $t(`forms.individual.integration.settings.font.google`) }}</span>
                  </v-btn>

                  <v-btn class="tw-w-1/2">
                    <span>{{ $t(`forms.individual.integration.settings.font.custom`) }}</span>
                  </v-btn>
                </v-btn-toggle>
                <div v-if="fontMode === 0">
                  <McFontpicker v-model="font"></McFontpicker>
                </div>
                <v-text-field
                  v-if="fontMode === 1"
                  v-model="customFont"
                  id="customFont"
                  solo
                  flat
                  outlined
                  hide-details
                />
              </div>
              <v-divider class="ui-separator"></v-divider>
              <div class="tw-mt-4 tw-px-4 tw-flex">
                <div class="tw-mr-2">
                  <div class="tw-mb-4" v-dompurify-html="$t('forms.individual.integration.settings.fontSize')"></div>
                  <v-text-field v-model="fontSize" id="fontSize" solo flat outlined hide-details />
                </div>
                <div class="tw-ml-2">
                  <div
                    class="tw-mb-4"
                    v-dompurify-html="$t('forms.individual.integration.settings.borderRadius')"
                  ></div>
                  <v-text-field
                    v-model.number="borderRadius"
                    id="borderRadius"
                    type="number"
                    min="0"
                    solo
                    flat
                    outlined
                    hide-details
                  />
                </div>
              </div>
              <v-divider class="ui-separator"></v-divider>
              <div class="tw-mt-4 tw-px-4 tw-flex">
                <div class="tw-ml-2">
                  <div
                    class="tw-mb-4"
                    v-dompurify-html="$t('forms.individual.integration.settings.options.label')"
                  ></div>
                  <v-switch
                    id="dense"
                    class="tw-mt-0"
                    v-model="isDense"
                    :label="$t('forms.individual.integration.settings.options.dense')"
                    hide-details
                  ></v-switch>
                  <v-switch
                    id="outlined"
                    class="tw-mt-0"
                    v-model="isOutlined"
                    :label="$t('forms.individual.integration.settings.options.outlined')"
                    hide-details
                  ></v-switch>
                  <v-switch
                    id="flat"
                    class="tw-mt-0"
                    v-model="isFlat"
                    :label="$t('forms.individual.integration.settings.options.flat')"
                    hide-details
                  ></v-switch>
                </div>
              </div>
              <v-divider class="ui-separator"></v-divider>
              <div class="tw-px-4 tw-flex">
                <v-checkbox
                  v-model="areClearables"
                  :label="$t(`forms.individual.integration.settings.clearable`)"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UiContainer>
  </v-fade-transition>
</template>

<script>
import { mapActions } from 'vuex'
import UiContainer from '@/components/UI/Container.vue'
import UiFormWidget from '@/components/UI/FormWidget.vue'
import UiCardForm from '@/components/UI/CardForm.vue'
import McFontpicker from '@mikk3lro/mc-fontpicker'
import hljsVuePlugin from '@/plugins/highlight'
import { destroyScript, formWidgetScriptTag, trackingScriptsTag } from '@/utils/formWidget.util'
import 'highlight.js/styles/railscasts.css'
import '@mikk3lro/mc-fontpicker/dist/mc-fontpicker.css'
import { getLastWidgetVersion } from '@/services/leads.service'

import { mdiContentCopy, mdiContentSaveOutline } from '@mdi/js'

export default {
  name: 'PlatformFormsIndividualIntegration',
  components: {
    UiContainer,
    UiFormWidget,
    UiCardForm,
    McFontpicker,
    Highlight: hljsVuePlugin.component,
  },

  props: {
    form: {
      type: Object,
      required: false,
      default: null,
    },
    updating: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    colorMode: 1,
    formKey: 0,
    dialog: false,
    color: '#1976D2FF',
    selectedColor: '',
    mask: '!#XXXXXXXX',
    font: 'Montserrat',
    customFont: null,
    fontSize: null,
    cssUrl: null,
    borderRadius: null,
    menu: false,
    seeCode: false,
    areClearables: false,
    fontMode: 0,
    isDense: true,
    isOutlined: true,
    isFlat: true,
    hasTracking: false,
    locale: null,
    selectableLocale: null,
    version: 'latest',

    icons: {
      mdiContentCopy,
      mdiContentSaveOutline,
    },
  }),

  async created() {
    this.version = await this.getLastWidgetVersion()
    this.locale = this.form.translations.activatedLocales[0]
  },

  computed: {
    currentClientUuid() {
      return this.form.clientId
    },

    generatedCode() {
      const codeString = `<!-- GTR Lead-Gen Widget - Start -->\n\n<gtr-form-widget\n  ${
        this.generateWidgetCodeAttributes
      } ></gtr-form-widget>\n${this.formWidgetScriptTag(this.version).outerHTML}\n\n<!-- GTR Lead-Gen Widget - End -->` // eslint-disable-line
      const trackingCodeString = `<!-- GTR Lead-Gen Widget - Start -->\n\n<gtr-form-widget\n  ${
        this.generateWidgetCodeAttributes
      } ></gtr-form-widget>\n${this.formWidgetScriptTag(this.version).outerHTML}\n${
        trackingScriptsTag(this.currentClientUuid, this.version).mainScript.outerHTML
      }\n${
        trackingScriptsTag(this.currentClientUuid, this.version).optionsScript.outerHTML
      }\n\n<!-- GTR Lead-Gen Widget - End -->` // eslint-disable-line
      return this.hasTracking ? trackingCodeString : codeString
    },
    generateWidgetCodeAttributes() {
      let attributes = `form-id="${this.form.id}"\n  locale="${this.locale}"\n  color="${this.color}"\n`

      if (this.colorMode === 0) attributes += `  dark="true"\n`
      if (this.isDense) attributes += `  dense="true"\n`
      if (this.isOutlined) attributes += `  outlined="true"\n`
      if (this.isFlat) attributes += `  flat="true"\n`
      if (this.cssUrl) attributes += `  css-url="${this.cssUrl}"\n`
      if (this.fontSize) attributes += `  font-size="${this.fontSize}"\n`
      if (this.computedBorderRadius) attributes += `  radius="${this.computedBorderRadius}"\n`
      if (this.areClearables) attributes += `  clearable="${this.areClearables}"\n`
      if (this.customFont) attributes += `  font="${this.customFont}"\n`
      if (!this.customFont) attributes += `  font="${this.font}"\n`

      return attributes
    },
    computedBorderRadius() {
      return this.borderRadius || this.borderRadius === 0 ? `${this.borderRadius}px` : null
    },
    hasMandatoryWidgetAttributes() {
      return this.locale && this.form.id
    },
    hasProducts() {
      return this.form.settings.product
    },
  },

  beforeDestroy() {
    destroyScript()
  },

  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),
    getLastWidgetVersion,
    formWidgetScriptTag,
    async copyCode() {
      try {
        await navigator.clipboard.writeText(this.generatedCode)
        this.setAlert({
          color: 'success',
          text: this.$t('forms.individual.integration.forms.copy.success'),
        })
      } catch ($e) {
        this.setAlert({
          color: 'error',
          text: this.$t('forms.individual.integration.forms.copy.fail'),
        })
      }
    },
    fontModeChange() {
      if (this.fontMode === 0) this.customFont = null
    },
    assignLocale(lang) {
      this.locale = lang
    },
  },
}
</script>

<style lang="scss">
.forms-individual-integration {
  &__form-settings {
    @apply tw-flex sm:tw-flex-row tw-flex-col;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
    border-radius: 8px;

    &__form {
      @apply tw-flex tw-flex-col;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        width: calc(100% - 450px);
      }

      &__background {
        @apply tw-m-4 tw-p-4;
      }

      &__code code {
        display: inline-block;
        background-color: #232520 !important;
        width: 100%;
        word-wrap: break-word;
        color: white !important;
      }
    }

    .dark {
      background-color: black;
    }
    .light {
      background-color: white;
    }

    &__settings {
      display: block;
      background: white;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      &__content {
        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          width: 450px;
        }
      }
    }
  }

  .mcfontpicker {
    border-radius: 5px;
    background-color: white;
    height: 56px;
    &__search {
      line-height: 56px;

      &:focus {
        opacity: 0;
      }
    }
    &__preview {
      margin-top: 10px;
    }

    &__popout {
      bottom: 100%;
      top: inherit;
    }
  }

  .ui-separator {
    @apply tw-my-4 tw-border-gray-200 dark:tw-border-opacity-10;
  }

  .ui-separator-title {
    @apply tw-mt-0 tw-mb-4 tw-border-gray-200 dark:tw-border-opacity-10;
  }
}
</style>
