<template>
  <v-fade-transition leave-absolute>
    <UiContainer class="forms-individual-translations tw-overflow-y-visible tw-w-full tw-h-full tw-pb-16">
      <form class="tw-flex tw-flex-col tw-gap-6" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <div>
          <!-- Language selection -->
          <div class="tw-font-semibold tw-text-lg tw-mb-4">
            {{ $t(`forms.individual.translations.selectLang.title`) }}
          </div>
          <div class="tw-flex tw-flex-wrap tw-gap-4">
            <div
              class="forms-individual-translations__selectLangItem"
              v-for="(lang, index) in modifiedLangList"
              :key="index"
            >
              <v-switch
                v-model="lang.active"
                :disabled="lang.locale === defaultLanguage"
                @change="toggleLang($event, lang)"
              ></v-switch>
              <v-img
                class="tw-ml-2"
                :src="getFlagImgSrc(lang)"
                max-width="30"
                min-width="30"
                hide-details
                v-if="lang.locale && getFlagImgSrc(lang)"
              />
              <span class="tw-ml-2 tw-font-semibold">{{ lang.locale.toUpperCase() }}</span>
            </div>
          </div>
        </div>
        <!-- Translations management -->
        <div class="tw-flex tw-flex-col sm:tw-flex-row tw-mt-4">
          <!-- Default language -->
          <UiCardForm
            class="tw-p-4"
            :class="{
              'tw-w-full sm:tw-w-1/2 sm:tw-mr-2': optionalLangItems.length,
              'sm:tw-w-full': !optionalLangItems.length,
            }"
          >
            <div class="tw-font-semibold tw-text-lg tw-mb-4">
              <div>{{ $t(`forms.individual.translations.cards.default.title`) }}</div>
              <div>
                <v-img
                  class="tw-ml-2"
                  :src="getFlagImgSrc(defaultLangItem)"
                  max-width="30"
                  min-width="30"
                  hide-details
                  v-if="defaultLangItem.locale && getFlagImgSrc(defaultLangItem)"
                />
              </div>
            </div>
            <div class="tw-mt-4">
              <v-btn class="tw-mb-6" color="primary" small @click="openDialogModal(defaultLangItem.locale)">{{
                $t(`forms.individual.translations.cards.default.reset`)
              }}</v-btn>

              <div class="tw-mb-5 tw-text-sm tw-text-gray-500">
                *: {{ $t(`forms.individual.translations.cards.mandatoryLabel`) }}
              </div>
              <div v-for="key in sortedTranslationKeys" :key="key">
                <v-text-field
                  v-model="modifiedTranslationsList[key][defaultLangItem.locale]"
                  :label="getTranslationFieldLabel(key)"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </div>
          </UiCardForm>

          <!-- Optional languages tabs -->
          <UiCardForm
            class="forms-individual-translations__optional tw-overflow-hidden tw-mt-4 sm:tw-mt-0"
            :class="{ 'tw-w-full sm:tw-w-1/2 sm:tw-ml-2': optionalLangItems.length }"
          >
            <div v-if="optionalLangItems.length">
              <v-tabs v-model="optionalLang">
                <v-tab v-for="lang in optionalLangItems" :key="lang.tab"
                  ><v-img
                    class="tw-ml-2"
                    :src="getFlagImgSrc(lang)"
                    max-width="30"
                    min-width="30"
                    hide-details
                    v-if="lang.locale && getFlagImgSrc(lang)"
                  />
                  <span class="tw-ml-2 tw-font-semibold">{{ lang.locale.toUpperCase() }}</span></v-tab
                >
              </v-tabs>
              <v-divider class="ui-separator"></v-divider>
              <v-tabs-items v-model="optionalLang">
                <v-tab-item v-for="lang in optionalLangItems" :key="lang.tab">
                  <div class="forms-individual-translations__optional__item">
                    <v-btn class="tw-mb-6" color="primary" small @click="openDialogModal(lang.locale)">{{
                      $t(`forms.individual.translations.cards.default.reset`)
                    }}</v-btn>
                    <div class="tw-mb-5 tw-text-sm tw-text-gray-500">
                      *: {{ $t(`forms.individual.translations.cards.mandatoryLabel`) }}
                    </div>
                    <div v-for="key in sortedTranslationKeys" :key="key">
                      <v-text-field
                        v-model="modifiedTranslationsList[key][lang.locale]"
                        :id="key"
                        :label="getTranslationFieldLabel(key)"
                        :error-messages="fieldErrors(key, lang)"
                        outlined
                        dense
                      ></v-text-field>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </UiCardForm>
          <!-- Save button -->
          <PlatformFormsIndividualUIEditActions v-if="hasEditedContent" type="submit" @cancelEdit="cancelEdit" />
        </div>

        <UiDialog :dialog="dialog" scrollable @close="dialog = false" :max-width="500">
          <template v-slot:header>
            <UiTitle :title="$t('forms.individual.translations.cards.default.modal.title')" />
          </template>
          <template v-slot:body>
            <div class="tw-px-4 tw-py-6 sm:tw-px-6">
              {{ $t('forms.individual.translations.cards.default.modal.body') }}
            </div>
          </template>
          <template v-slot:actions>
            <v-btn type="submit" rounded text @click="dialog = false">
              {{ $t('button.cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn type="submit" rounded color="primary" @click="resetDefaultTranslations(openedDialogLang)">
              {{ $t('button.confirm') }}
            </v-btn>
          </template>
        </UiDialog>
      </form>
    </UiContainer>
  </v-fade-transition>
</template>

<script>
import UiContainer from '@/components/UI/Container.vue'
import UiDialog from '@/components/UI/Dialog.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiCardForm from '@/components/UI/CardForm.vue'
import { mapActions } from 'vuex'
import { defaultLangList } from '@/config/formTranslations.config'
import { mdiContentSaveCheckOutline } from '@mdi/js'
import { deepMerge } from '@/utils/utilities.util'
import PlatformFormsIndividualUIEditActions from '@/components/Forms/Individual/UI/EditActions.vue'
import { clone } from '@/utils/utilities.util'
import { isEmpty } from '@/utils/helper.util'

export default {
  name: 'PlatformFormsIndividualTranslations',
  components: {
    UiContainer,
    PlatformFormsIndividualUIEditActions,
    UiDialog,
    UiTitle,
    UiCardForm,
  },
  props: {
    translations: {
      type: Object,
      required: true,
    },
    dynamicTranslations: {
      type: Object,
      required: true,
    },
    defaultTranslations: {
      type: Object,
      required: true,
    },
    mandatoryTranslations: {
      type: Array,
      required: true,
    },
    defaultLanguage: {
      type: String,
      required: false,
      default: 'en',
    },
  },
  data: () => ({
    defaultLangList,
    modifiedTranslationsList: {},
    modifiedLangList: [],
    optionalLang: '',
    openedDialogLang: '',
    dialog: false,
    icons: [mdiContentSaveCheckOutline],
    sectionKey: 0,
    initialTranslationList: [],
    initialLangList: [],
  }),
  created() {
    this.modifiedTranslationsList = this.formatedTranslations
    this.modifiedLangList = this.langList
    this.initialTranslationList = clone(this.modifiedTranslationsList)
    this.initialLangList = clone(this.modifiedLangList)
  },
  watch: {
    modifiedTranslationsList: {
      handler() {
        this.$emit('contentModified', this.hasEditedContent, 'translations')
      },
      deep: true,
      immediate: false,
    },
    modifiedLangList: {
      handler() {
        this.$emit('contentModified', this.hasEditedContent, 'translations')
      },
      deep: true,
    },
  },
  computed: {
    defaultLangItem() {
      return this.langList.find(item => item.locale === this.defaultLanguage)
    },
    optionalLangItems() {
      return this.modifiedLangList.filter(item => item.locale !== this.defaultLanguage && item.active === true)
    },
    formatedTranslations() {
      const translationsWithDynamic = deepMerge(this.dynamicTranslations, this.defaultTranslations)
      return deepMerge(translationsWithDynamic, this.translations.list)
    },
    langList() {
      return defaultLangList.map(lang => {
        const foundLang = this.translations.activatedLocales.some(value => value === lang.locale)

        return { ...lang, active: foundLang ? true : false }
      })
    },
    sortedTranslationKeys() {
      return Object.keys(this.modifiedTranslationsList).sort()
    },
    hasEditedContent() {
      return (
        JSON.stringify(this.modifiedTranslationsList) != JSON.stringify(this.initialTranslationList) ||
        JSON.stringify(this.modifiedLangList) != JSON.stringify(this.initialLangList)
      )
    },
    mandatoryErrors() {
      return this.modifiedLangList
        .map(lang =>
          this.mandatoryTranslations
            .filter(key => {
              return this.fieldErrors(key, lang).length
            })
            .map(key => `${lang.locale}.${key}`)
        )
        .flat()
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),
    getFlagImgSrc(item) {
      try {
        return require(`@/assets/images/flags/${item.locale}.svg`)
      } catch {
        return null
      }
    },
    toggleLang(bool, item) {
      if (bool) {
        this.addLang(item.locale)
      } else {
        this.removeLang(item.locale)
      }
    },

    addLang(locale) {
      const modifiedLangIndex = this.modifiedLangList.findIndex(modifiedLang => modifiedLang.locale === locale)
      this.modifiedLangList[modifiedLangIndex].active = true
    },

    removeLang(locale) {
      const modifiedLangIndex = this.modifiedLangList.findIndex(modifiedLang => modifiedLang.locale === locale)
      this.modifiedLangList[modifiedLangIndex].active = false
    },

    openDialogModal(locale) {
      this.dialog = true
      this.openedDialogLang = locale
    },

    resetDefaultTranslations(locale) {
      this.modifiedTranslationsList = deepMerge(this.modifiedTranslationsList, this.getDefaultTranslations(locale))
      this.setAlert({
        color: 'success',
        text: this.$t('forms.individual.translations.cards.default.modal.success'),
      })
      this.dialog = false
    },

    getDefaultTranslations(locale) {
      const defaultTranslationsForLocale = {}
      for (const defaultTranslationKey in this.defaultTranslations) {
        defaultTranslationsForLocale[defaultTranslationKey] = {
          [locale]: this.defaultTranslations[defaultTranslationKey][locale],
        }
      }
      return defaultTranslationsForLocale
    },

    isMandatoryTranslation(translationKey) {
      return this.mandatoryTranslations.includes(translationKey)
    },

    getTranslationFieldLabel(translationKey) {
      return this.isMandatoryTranslation(translationKey) ? `${translationKey}*` : translationKey
    },
    cancelEdit() {
      this.modifiedTranslationsList = clone(this.initialTranslationList)
      this.modifiedLangList = clone(this.initialLangList)
      this.sectionKey++
    },
    onSubmit() {
      if (this.mandatoryErrors.length) {
        this.setAlert({
          color: 'error',
          text: this.$t('forms.individual.translations.errors.mandatory', {
            translations: this.mandatoryErrors.join(' - '),
          }),
        })
        return
      }
      this.$emit('updateTranslations', {
        list: this.modifiedTranslationsList,
        activatedLocales: this.modifiedLangList.filter(lang => lang.active).map(lang => lang.locale),
      })
    },
    fieldErrors(key, lang) {
      return this.isMandatoryTranslation(key) && isEmpty(this.modifiedTranslationsList[key][lang.locale])
        ? [this.$t('error.required')]
        : []
    },
    isEmpty,
  },
}
</script>

<style lang="scss">
.forms-individual-translations {
  &__selectLangItem {
    @apply tw-py-2 tw-px-3 tw-flex tw-items-center tw-bg-white;
    width: 135px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
    border-radius: 8px;

    .v-input--selection-controls {
      margin-top: 0;
    }
    .v-messages {
      display: none;
    }
  }
  &__optional {
    &__item {
      margin-top: 17px;
    }
  }
  .ui-separator {
    @apply tw-mt-0 tw-border-gray-200 dark:tw-border-opacity-10;
  }
}
</style>
