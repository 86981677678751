<template>
  <v-menu @input="val => (show = val)" :close-on-content-click="false" transition="scale-transition" min-width="auto">
    <template v-slot:activator="{ on }">
      <v-text-field
        class="tw-font-normal tw-text-sm"
        :value="currentColor"
        id="brandColor"
        readonly
        solo
        flat
        outlined
        dense
        :placeholder="placeholder"
        v-on="on"
        :append-icon="icons.mdiEraser"
        @click:append="updateRemoveColor"
      >
        <template v-slot:prepend-inner>
          <v-avatar :id="id" @click="on.click" tile size="24" :color="currentColor || '#cbd5e1'" />
        </template>
      </v-text-field>
    </template>
    <v-color-picker
      v-if="show"
      :value="currentColor || ''"
      :mode.sync="colorMode"
      hide-mode-switch
      dot-size="20"
      @input="colorUpdate"
    />
  </v-menu>
</template>

<script>
import { mdiEraser } from '@mdi/js'

export default {
  name: 'UiInputColorPicker',
  props: {
    id: {
      type: String,
      required: true,
    },
    currentColor: {
      type: String,
      required: false,
      default: '',
    },
    colorMode: {
      type: String,
      required: false,
      default: 'hexa',
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      icons: {
        mdiEraser,
      },
    }
  },
  methods: {
    colorUpdate(color) {
      this.$emit('colorUpdate', color)
    },
    async updateRemoveColor() {
      this.$emit('colorUpdate', null)
    },
  },
}
</script>
